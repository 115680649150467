import React, { Component } from "react"
import { Image } from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-gtag'

class Joomla extends Component {
  render() {
    return (
      <div className="wrapper-skill">
        <Image
          alt="900x500"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
          data-src={require("../../../../images/skill/joomla.jpg")}
          className="bannerSkill lazyload"
        />
        <div className="tab-skill">
          <OutboundLink to="/capabilities/php-mysql" activeClassName="active">Php/mysql</OutboundLink>
          <OutboundLink to="/capabilities/jquery-ajax" activeClassName="active">Jquery/Ajax</OutboundLink>
          <OutboundLink to="/capabilities/magento" activeClassName="active">Magento</OutboundLink>
          <OutboundLink to="/capabilities/joomla" activeClassName="active">Joomla</OutboundLink>
          <OutboundLink to="/capabilities/wordpress" activeClassName="active">WordPress</OutboundLink>
          <OutboundLink to="/capabilities/html-css" activeClassName="active">Css3/html5</OutboundLink>
          <OutboundLink to="/capabilities/pts-illus" activeClassName="active">Phptoshop/Illustrator</OutboundLink>
          <OutboundLink to="/capabilities/nginx" activeClassName="active">Apache/Nginx</OutboundLink>
          <OutboundLink to="/capabilities/varnish" activeClassName="active">Varnish</OutboundLink>
        </div>
        <div className="content-skill">
          <h1 className="title-page-skill">Joomla</h1>
          <div>
            <div className="left-content-skill">
              <p>We have built Joomla sites for schools, churches, synagogues, corporations, small businesses, software companies, constructions companies, hospitals, non-profits, online magazines, shopping stores, subscription based companies. We do not use short-term developers or offshore teams. We have in-house development team of many years</p>
            </div>
            <div className="right-content-skill">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/content-joomla.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
          <br/>
          <div>
            <div className="left-content-skill reverse">
              <span>Our team of expert Joomla developers are here to assist you with:</span>
              <p className="sub-content-skill">
                - Joomla Development – Custom components, modules and plugins or modifications to existing ones.<br/>
                - Joomla Templates – Custom Photoshop design using the Warp or Gantry frameworks.<br/>
                - Joomla Template Conversions – Convert any site or file format to a Joomla template.<br/>
                - Joomla Website Packages – You pick the package, we install it for you.<br/>
              </p>
            </div>
            <div className="right-content-skill reverse">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/content-joomla-2.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
          <br/>
          <div>
            <div className="left-content-skill">
              <p className="sub-content-skill">
                - Joomla Support – From general maintenance, to template and programming issues. We are here to help.<br/>
                - Joomla Site Security – we’ll audit your site, report how security can be improved and implement our suggestions.<br/>
                - Search Engine Optimisation for Joomla sites.<br/>
                - Joomla Migrations – Migrate your Joomla 1.0/1.5 site to Joomla 2.5/3.x.<br/>
                - Joomla Training – Joomla and other extension training available via Skype or phone.<br/>
                - Reponsive Templates – Enhance your current template and ensure it displays correctly on tablets and mobile phones.<br/>
              </p>
            </div>
            <div className="right-content-skill">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/content-joomla-3.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Joomla;
