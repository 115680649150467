import React from "react"

import Layout from "../../components/layout"
import Joomla from "../../components/screens/Skill/Joomla"
import { Helmet } from "react-helmet"

const JoomlaPage = () => (
  <Layout pageInfo={{ pageName: "Joomla" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Joomla</title>
        <meta name="description" content="Joomla" />
    </Helmet>
    <Joomla />
  </Layout>
)

export default JoomlaPage
